var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"mobileForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"identification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Identification "}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"เลขบัตรประจำตัวประชาชน","type":"text"},model:{value:(_vm.identification),callback:function ($$v) {_vm.identification=$$v},expression:"identification"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Day"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"01 เลขวัน 2 หลัก","type":"text"},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Month"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"01 เลขเดือน 2 หลัก","type":"text"},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Year (YYYY) "}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"2001 ปี ค.ศ.","type":"text"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pin no."}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"123456 PIN","type":"text"},model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=$$v},expression:"pin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"mobile_phone_no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Mobile number"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"08XXXXXXX เบอร์โทร","type":"text"},model:{value:(_vm.mobilePhoneNo),callback:function ($$v) {_vm.mobilePhoneNo=$$v},expression:"mobilePhoneNo"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"device_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Device Brand"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"samsung","type":"text"},model:{value:(_vm.deviceBrand),callback:function ($$v) {_vm.deviceBrand=$$v},expression:"deviceBrand"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"device_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Device Code"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"SM-A207F/DS","type":"text"},model:{value:(_vm.deviceCode),callback:function ($$v) {_vm.deviceCode=$$v},expression:"deviceCode"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"account_license","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"account_license ***"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.license),callback:function ($$v) {_vm.license=$$v},expression:"license"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Register ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }