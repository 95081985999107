<template>
  <validation-observer ref="mobileForm">
    <form @submit.prevent="onSubmit">
      <validation-provider
        v-slot="{ errors }"
        name="identification"
        rules="required"
      >
        <b-form-group label="Identification ">
          <b-form-input
            v-model="identification"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            placeholder="เลขบัตรประจำตัวประชาชน"
            type="text"
          />
          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-row class="mt-4">
        <b-col md="4">
          <validation-provider
            v-slot="{ errors }"
            name="day"
            rules="required"
          >
            <b-form-group label="Day">
              <b-form-input
                v-model="day"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="01 เลขวัน 2 หลัก"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            v-slot="{ errors }"
            name="month"
            rules="required"
          >
            <b-form-group label="Month">
              <b-form-input
                v-model="month"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="01 เลขเดือน 2 หลัก"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            v-slot="{ errors }"
            name="year"
            rules="required"
          >
            <b-form-group label="Year (YYYY) ">
              <b-form-input
                v-model="year"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="2001 ปี ค.ศ."
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="pin"
            rules="required"
          >
            <b-form-group label="Pin no.">
              <b-form-input
                v-model="pin"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="123456 PIN"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="mobile_phone_no"
            rules="required"
          >
            <b-form-group label="Mobile number">
              <b-form-input
                v-model="mobilePhoneNo"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="08XXXXXXX เบอร์โทร"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="device_id"
            rules="required"
          >
            <b-form-group label="Device Brand">
              <b-form-input
                v-model="deviceBrand"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="samsung"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="device_code"
            rules="required"
          >
            <b-form-group label="Device Code">
              <b-form-input
                v-model="deviceCode"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="SM-A207F/DS"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="account_license"
            rules="required"
          >
            <b-form-group label="account_license ***">
              <b-form-input
                v-model="license"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <div class="d-flex">
        <b-button
          variant="primary"
          class="ml-auto"
          type="submit"
        >
          Register
        </b-button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { required } from '@validations'
export default {
  name: 'MobileBankingForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      
      license: '',
      identification: '',
      year: '',
      month: '',
      day: '',
      pin: '',
      mobilePhoneNo: '',
      deviceBrand: 'samsung',
      deviceCode: 'SM-A207F/DS',
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    await this.setData()
  },
  methods: {
    async setData() {
      if (this.value && this.value.id) {
        this.identification = this.value.identification
        this.year = this.value.year
        this.month = this.value.month
        this.day = this.value.day
        this.pin = this.value.pin
        this.mobilePhoneNo = this.value.mobilePhoneNo
        this.license = this.value.license
        this.deviceBrand = this.value.deviceBrand
        this.deviceCode = this.value.deviceCode
      } else {
        this.identification = ''
        this.year = ''
        this.month = ''
        this.day = ''
        this.pin = ''
        this.mobilePhoneNo = ''
        this.license = ''
        this.deviceBrand = 'samsung'
        this.deviceCode = 'SM-A207F/DS'
      }
    },
    onAgentIdChange(agent_id) {
      this.agent_id = agent_id
    },
    onSubmit() {
      this.$refs.mobileForm.validate().then( async (success) => {
        if (success) {
          this.$emit('submit', {
            identification: this.identification,
            year: this.year,
            month: this.month,
            day: this.day,
            pin: this.pin,
            mobilePhoneNo: this.mobilePhoneNo,
            license: this.license,
            deviceBrand: this.deviceBrand,
            deviceCode: this.deviceCode,
          })
        }
      })
    },
    onCancel() {
      this.$emit('close')
    },
    verifyAccount() {},
  },
}
</script>
